import React from 'react';
import TaskItem from './TaskItem';
import '../view_DetalleCategoria.scss'
import { ScrollView } from 'devextreme-react';

interface IProps{
    data: any
}

function TabPanelItem({ data }: IProps) {
  const taskItems = data.tasks.map((task: any, index: React.Key | null | undefined) => (
    <TaskItem
      key={index}
      color={ task.status != "Completed" && index === 0 ? <div style={{ backgroundColor: "#ff5722", width:"5px", height:"-webkit-fill-available" }}></div> : <div></div> }
      prop={task}
    />
  ));
  return <ScrollView> <div className="tabpanel-item">{taskItems}</div> </ScrollView>;
}
export default TabPanelItem;