import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { signIn as sendSignInRequest, GetUserByUserID } from '../api/auth';
import type { User, AuthContextType } from '../types';

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      let UserId: any = localStorage.getItem("user_id")
      if (UserId !== "undefined") {
        let dtLogin: any = await GetUserByUserID(UserId)
        if(dtLogin !== undefined) signIn(dtLogin.user, dtLogin.password)
      }
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email: string, password: string) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result.data);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext<AuthContextType>({ loading: false } as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
