import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import 'devextreme/data/odata/store';
import HtmlEditor, { Toolbar, MediaResizing, ImageUpload, Item } from 'devextreme-react/html-editor';
import RadioGroup, { RadioGroupTypes } from 'devextreme-react/radio-group';
import DateBox, { DateBoxTypes } from 'devextreme-react/date-box';
import { Button, TextBox, Accordion, FileUploader } from 'devextreme-react';
import Switch, { SwitchTypes } from 'devextreme-react/switch';
import notify from 'devextreme/ui/notify';
import * as Fnctn from '../../contexts/GenericFunctions';
import '../tasks/tasks.scss'
import { connect } from 'react-redux'
import Categories from '../../components/GetCategories';
import { RootState } from '../../reduxStore/reducers'
import { Link } from 'react-router-dom'
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';
import * as FireBase from '../../contexts/FirebaseActions'
import { Cantidad, Tiempo, Secuencia, Notificaciones, Audiencia } from '../../components/Tasks/componentTasks'


let sgAreas: [] = []
  , sgBranches: [] = []
  , sgCompanies: [] = []
  , selectedItems: [] = []
  , segmentacion: any = {
    areas: [],
    branches: [],
    companies: [],
    type_collaborators: []
  };
let areas: any = [], branches: any = [], companies: any = [];

function Task(props: any) {
  let api = Fnctn.GetAPI()

  const htmlEditorRef = useRef<any>(null);

  const [urlImage, setUrlImage] = useState<string>(props.Select_Img);
  const [imgName, setImgName] = useState<string>(props.Select_ImgName);
  const [Titulo, setTitulo] = useState<string>(props.Select_Titulo);
  const [diasNotificacionVencimiento, setDiasNotificacionVencimiento] = useState<any>(props.Select_DiasNotificacionVencimiento);
  const [selectionVencimiento, setVencimiento] = useState<boolean>(props.Select_Vencimiento);
  const [cantidad, setCantidad] = useState<string>(props.Select_Cantidad);
  const [selectionTiempo, setTiempo] = useState<string>(props.Select_Tiempo);
  const [selectionSecuencia, setSecuencia] = useState<string>(props.Select_Secuencia);
  const [selectionCategoria, setCategoria] = useState<string>(props.Select_Categoria);
  const [selectionNotificacion, setNotificacion] = useState<string>(props.Select_Notificacion);
  const [switchAsignacionAutomatica, setAsignacionAutomatica] = useState<boolean>(props.Select_AutoAsignacion);
  const [labelText, setLabelText] = useState<string>('Sin enlaces');
  const [selectionAsignacion, setAsignacion] = useState<string>(props.Select_TipoAsignacion);
  const [VisibleVencimiento, setVisibleVencimiento] = useState<boolean>(props.Select_Vencimiento);
  const [VisibleRangoAsignacion, setVisibleRangoAsignacion] = useState<boolean>(false);
  const [VisibleAudiencia, setVisibleAudiencia] = useState<boolean>(props.Select_SegmentacionType);
  const [Desde, setDesde] = useState<Date>(props.Select_From);
  const [Hasta, setHasta] = useState<Date>(props.Select_To);
  const [ArchivosAdjuntos, setArchivosAdjuntos] = useState<any>(props.Select_Attachments);
  const [Evaluation_ID, setEvaluation_ID] = useState<any>(props.Select_Evaluation_Id);
  const [FotoTarea, setFotoTarea] = useState<any>(null);
  const [dtArea, setDtArea] = useState<any>([]);
  const [dtEmpresa, setDtEmpresa] = useState<any>([]);
  const [dtSucursal, setDtSucursal] = useState<any>([]);
  const [dtVideos, setDtVideos] = useState<any>([]);
  const [dtFiles, setDtFiles] = useState<any>([]);
  const [linkString, setlinkString] = useState<string>();
  const [FilesAttachments, setFilesAttachments] = useState<any>([]);
  const [selectedIdsSave, setSelectedIdsSave] = useState<any>([]);
  const [VisibleTbLink, setVisibleTbLink] = useState<boolean>(false);
  const [Order] = useState<any>(props.select_Order);
  const [accordionCm, setaccordionCm] = useState<any>([]);
  const fontValues = [
    'Arial',
    'Courier New',
    'Georgia',
    'Impact',
    'Lucida Console',
    'Tahoma',
    'Times New Roman',
    'Verdana',
  ];
  const OpcionesVencimiento = [
    { Codigo: false, Nombre: 'Sin fecha de vencimiento' },
    { Codigo: true, Nombre: 'Con fecha de vencimiento' }
  ];
  const ItemsTiempo = [
    { Codigo: "D", Nombre: 'Días' },
    { Codigo: "S", Nombre: 'Semanas' },
    { Codigo: "A", Nombre: 'Años' }
  ];
  const ItemsSecuencia = [
    { Codigo: "before", Nombre: 'Antes' },
    { Codigo: "after", Nombre: 'Después' }
  ];
  const ItemsNotificacion = [
    { Codigo: false, Nombre: 'No notificar' },
    { Codigo: true, Nombre: 'Notificar' }
  ];
  const ItemsAudiencia = [
    { Codigo: false, Nombre: 'Todos los usuarios' },
    { Codigo: true, Nombre: 'Segmentar' }
  ];
  const itemsAccordion = [
    { title: 'Area', Codigo: "areas" },
    { title: 'Sucursal', Codigo: 'branches' },
    { title: 'Empresa', Codigo: 'companies' }
  ];
  const OpcionesAsignacion = [
    { Codigo: "C", Nombre: 'El día de contratación' },
    { Codigo: "A", Nombre: 'Ahora mismo' }
  ];
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setFotoTarea(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String: string = reader.result!.toString();
        setUrlImage(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const OnFocusOutText = (url: string = "") => {
    let objLink: any = {
      "archive_name": "-",
      "extension": ".mp4",
      "url": !Fnctn.NullUndefinedEmpty(linkString) ? linkString : url,
      "id_firebase": "-"
    }

    if (!Fnctn.NullUndefinedEmpty(objLink.url)) {
      if (ArchivosAdjuntos.every((obj: any) => !obj.url.includes(objLink.url))) {
        let updateArchivos = [...ArchivosAdjuntos, objLink];
        setArchivosAdjuntos(updateArchivos);
        setVisibleTbLink(false);

        if (updateArchivos.length > 0) setLabelText('Enlaces adjuntos: ' + updateArchivos.length);
        else setLabelText('No hay documentos adjuntos');

        cardVideo(updateArchivos, updateArchivos);
      }
      else {
        notify("Ese url ya se encuentra asjunto", "error")
      }
    }
    else {
      notify("El elemento no contiene el formato correcto", "error")
    }
  };

  // const obtenerIdVideo = (url: string) => {
  //   // Expresión regular para extraer la ID del video de la URL
  //   const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  //   const match = url.match(regex);
  //   if (match && match[1]) { return match[1]; }
  //   else { return null; }
  // }

  const onValueChangedLink = async (e: any) => {
    await setVisibleTbLink(true);
    await setlinkString("");
  };

  const onValueChangedFiles = async (e: any) => {
    setFilesAttachments(e.value);
  };

  const copyToClipboard = (textToCopy: string) => {

    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        notify('Url copiada', "success");
      })
      .catch(err => {
        notify('La url no ha sido copiada', "error");
      });
  }

  const deleteLink = async (url: string, links: any) => {
    let newArray = await links.filter((element: any) => element.url !== url && element.extension === ".mp4");
    cardVideo(links, newArray);
    setArchivosAdjuntos(newArray);
  }

  const cardVideo = async (obj: any, lastArray: any) => {
    let newPortada = [];
    let prevVideos = lastArray.filter((item: any) => item.extension === ".mp4")
    for (let i = 0; i < lastArray.length; i++) {
      // const youtubeUrl = `${lastArray[i].url}?start=0&autoplay=1`;
      let ComponentPortada = <div className='cardVideo'>
        <div className='cardVideo-content' id={lastArray[i].url}>
          {/* <video className='imgVideo' src={lastArray[i].url} onClick={() => handleDivClick(obj[i].url)}>
            <source className='Source' src={lastArray[i].url + `#t=0,7`} />
          </video> */}
          {/* <video  id={lastArray[i].url} className='imgVideo' onClick={() => handleDivClick(obj[i].url)}>
                <source className='Source' src={lastArray[i].url + `#t=7`}/>
            </video> */}
          <iframe
            title='Video'
            className='imgVideo'
            src={lastArray[i].url}
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className='opcionesPortadas'>
            <span><label className='copiarURL' onClick={() => copyToClipboard(obj[i].url)}>Copiar</label></span>
            <span><label className='eliminarURL' onClick={() => deleteLink(obj[i].url, prevVideos)}>Eliminar</label></span>
          </div>
        </div>
      </div>
      newPortada.push(ComponentPortada)
    }
    let videos: any = []
    setDtVideos(videos);
    setDtVideos(newPortada);
  };

  const deleteFile = (id_file: string) => {
    FireBase.DeleteFile(id_file, FireBase.enumPath.ARCHIVOS_TAREAS);
    let newArchivos = ArchivosAdjuntos.filter((element: any) => element.id_firebase !== id_file);
    let prevFiles = newArchivos.filter((item: any) => item.extension !== ".mp4");
    setArchivosAdjuntos(newArchivos);
    cardFile(prevFiles);
  }

  const cardFile = async (lastArray: any) => {
    let newPortada = [];
    let prevVideos = lastArray.filter((item: any) => item.extension !== ".mp4")
    for (let i = 0; i < prevVideos.length; i++) {
      let ComponentPortada = <div style={{ width: "100%", display: "flex" }}>
        <div className='cardFileElement'>
          <div className='cardFileElement-content' id={lastArray[i].url} onClick={() => Fnctn.OpenFile(lastArray[i].url)}>
            {prevVideos[i].archive_name}
          </div>
        </div>
        <i className='dx-icon dx-icon-remove icon_DeleteFile' onClick={() => deleteFile(lastArray[i].id_firebase)}></i>
      </div>
      newPortada.push(ComponentPortada)
    }
    setDtFiles(newPortada);
  };

  // const handleDivClick = (url: string) => {
  //   window.open(url, '_blank');
  // };

  // let GetAudiencias = async () => {
  //   try {
  //     halcoloaderActive();
  //     const data = Fnctn.getCookie();
  //     let dtresponseArea = await fetch(`${api}/catalogs/areas`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } });
  //     setDtArea(await dtresponseArea.json());
  //     let dtresponseSucursal = await fetch(`${api}/catalogs/branches`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } });
  //     setDtSucursal(await dtresponseSucursal.json());
  //     let dtresponseEmpresa = await fetch(`${api}/catalogs/companies`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } });
  //     setDtEmpresa(await dtresponseEmpresa.json());
  //     halcoloaderRemove();
  //   }
  //   catch (error) {
  //     halcoloaderRemove();
  //     notify(error, "error");
  //   }
  // };

  useEffect(() => {
    if (!Fnctn.NullUndefinedEmpty(props.Select_Content)) {
      htmlEditorRef.current.instance.option('value', props.Select_Content)
    }

    if (!Fnctn.NullUndefinedEmpty(props.Select_Content))
      setaccordionCm(<Accordion
        dataSource={itemsAccordion}
        animationDuration={500}
        itemRender={itemRenderAccordion}
        id="accordion-container"
        style={{ margin: "0px 0px 0px 35px", width: "95%" }}
      />)
  }, [props.Select_IdTarea]);

  const changeSelectionRB = (e: RadioGroupTypes.ValueChangedEvent, Radio: String) => {
    if (Radio === "Vencimiento") {
      setVencimiento(e.value);
      if (e.value) { setVisibleVencimiento(true); }
      else {
        setVisibleVencimiento(false);
        setCantidad("");
        setTiempo("");
        setSecuencia("");
      }
    }
    else if (Radio === "Audiencia") {
      if (!e.value) setVisibleAudiencia(false);
      else {
        setVisibleAudiencia(true);
        setaccordionCm(<Accordion
          dataSource={itemsAccordion}
          animationDuration={500}
          itemRender={itemRenderAccordion}
          id="accordion-container"
          style={{ margin: "0px 0px 0px 35px", width: "95%" }}
        />)
        // GetAudiencias();
      }
    }
    else if (Radio === "Asignacion") {
      setAsignacion(e.value);
      if (e.value === "R") setVisibleRangoAsignacion(true);
      else setVisibleRangoAsignacion(false);
    }
  };

  const handleTextChanged = (e: any, Textbox: string = "") => {
    if (Textbox === "Titulo") setTitulo(e.value);
    else if (Textbox === "Cantidad") setCantidad(e.value);
    else if (Textbox === "URL") setlinkString(e);
    else if (Textbox === "DiasNotificacionVencimiento") setDiasNotificacionVencimiento(e.value);
  };

  const onValueChangedList = useCallback((e: any, SelectBox: String) => {
    try {
      if (SelectBox === "Tiempo") setTiempo(e.selectedItem.Codigo);
      if (SelectBox === "Secuencia") setSecuencia(e.selectedItem.Codigo);
      if (SelectBox === "Categoria") setCategoria(e.selectedItem._id);
      if (SelectBox === "Notificacion") setNotificacion(e.selectedItem.Codigo);
      return e.value;
    }
    catch {

    }
  }, []);

  const onValueChangedDateBox = useCallback((e: DateBoxTypes.ValueChangedEvent, DateBox: string) => {
    if (DateBox === "Desde") {
      setDesde(e.value);
    }
    else if (DateBox === "Hasta") {
      setHasta(e.value);
    }
  }, []);

  const getIds = (ids: any, list: string) => {
    if (list === "areas") {
      segmentacion.areas = [ids[0]]
    }
    else if (list === "branches") {
      segmentacion.branches = [ids[0]]
    }
    else if (list === "companies") {
      segmentacion.companies = [ids[0]]
    }

    console.log(segmentacion);
  };

  const itemRenderAccordion = useMemo(() => (itemData: any) => {
    return (
      <Audiencia props={itemData} selectedItems={props.Select_Segmentacion} getIds={getIds}></Audiencia>
    )
  }, [dtArea.data, dtSucursal.data, dtEmpresa.data]);

  const valueSwitchChanged = useCallback((e: SwitchTypes.ValueChangedEvent) => {
    setAsignacionAutomatica(e.value);
    if (switchAsignacionAutomatica) {
      setAsignacion("");
    }
  }, []);

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}T00:30:00Z`;
  };

  const handleSelectionCategoria = (value: any) => {
    setCategoria(value);
  }

  const returnIds = (arrayObj: any) => {
    let arrayIds: any = []
    arrayObj.forEach((element: any) => {
      arrayIds.push(element._id);
    });
    return arrayIds;
  }

  const OrderTareas = async (IdCategoria: string) => {
    const cookie = Fnctn.getCookie()

    const api = Fnctn.GetAPI()
    const token = cookie != null ? cookie.token : null
    const IdUsuario = cookie != null ? cookie._id : null

    const url = `${api}/training/onbording_users/${IdCategoria}?user_id=${IdUsuario}`
    const requestOptions = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    }

    let response = await fetch(url, requestOptions)
    let tareas = await response.json();
    tareas = [...tareas.data].sort((a, b) => a.order_task - b.order_task);
    for (let i: number = 0; i < tareas.length; i++) {
      tareas[i].order_task = i;
    }
    tareas.forEach(async (element: any) => {
      let Objt = { "order": element.order_task }

      await fetch(`${api}/training/Task/${element._id}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(Objt)
      });
    });
  }

  const Guardar = async () => {
    try {
      halcoloaderActive();
      const data = Fnctn.getCookie();
      let asignacion: string = "T"
      let content: any = htmlEditorRef.current.instance.option('value')
      let asignaciones: any = ["C", "A", "R"]

      if (Fnctn.NullUndefinedEmpty(Titulo)) throw new Error("Debes agregar un titulo a la tarea");
      if (Fnctn.NullUndefinedEmpty(content)) throw new Error("Debes ingresar algo en el contenido de la tarea");
      if (selectionVencimiento) {
        if (Fnctn.NullUndefinedEmpty(Cantidad)) throw new Error("Debes agregar una cantidad");
        if (Fnctn.NullUndefinedEmpty(selectionSecuencia)) throw new Error("Debes seleccionar una secuencia");
        if (Fnctn.NullUndefinedEmpty(selectionTiempo)) throw new Error("Debes seleccionar un tiempo para el vencimiento de la tarea");
      }
      if (Fnctn.NullUndefinedEmpty(selectionCategoria)) throw new Error("Debes seleccionar una categoría");
      if (Fnctn.NullUndefinedEmpty(selectionNotificacion)) throw new Error("Debes elegir si notificar la tarea o no");
      if (switchAsignacionAutomatica) {
        if (Fnctn.NullUndefinedEmpty(selectionAsignacion)) throw new Error("Debes elegir una asignacion a la tarea");
      }
      if (selectionAsignacion === "R") {
        if (Fnctn.NullUndefinedEmpty(Desde) || Fnctn.NullUndefinedEmpty(Hasta)) {
          throw new Error("Debes ingresar un rango de fechas válidas");
        }
      }
      if (!asignaciones.includes(selectionAsignacion) || selectionAsignacion === "T") {
        await setAsignacion("T");

      }
      if (!VisibleAudiencia) {
        const data = Fnctn.getCookie();
        let dtresponseArea = await fetch(`${api}/catalogs/areas`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } });
        let areasTodos: any = await dtresponseArea.json();
        areasTodos = returnIds(areasTodos.data);
        let dtresponseSucursal = await fetch(`${api}/catalogs/branches`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } });
        let branchesTodos = await dtresponseSucursal.json();
        branchesTodos = returnIds(branchesTodos.data);
        let dtresponseEmpresa = await fetch(`${api}/catalogs/companies`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } });
        let companiesTodos = await dtresponseEmpresa.json();
        companiesTodos = returnIds(companiesTodos.data);

        segmentacion = {
          "areas": areasTodos,
          "branches": branchesTodos,
          "companies": companiesTodos,
          "type_collaborators": []
        };
      }

      let DesdeFormateado = formatDate(Desde)
      let HastaFormateado = formatDate(Hasta)
      if (Fnctn.NullUndefinedEmpty(Evaluation_ID)) {
        await setEvaluation_ID(null);
      }

      let IconoTarea: any = null;
      let defaultIcon = "https://firebasestorage.googleapis.com/v0/b/halconet-27dd9.appspot.com/o/HalcoHumand%2FICONOS_TAREAS%2Flogo-halconet_sinletras.svg?alt=media&token=cd51f543-4a9b-4685-85f7-50f9976b4146"
      if (!Fnctn.NullUndefinedEmpty(FotoTarea)) {
        if (!imgName.includes("sinletras")) {
          IconoTarea = await FireBase.DeleteFile(imgName, FireBase.enumPath.ICONOS_TAREAS);
        }
        IconoTarea = await FireBase.uploadFile(FotoTarea, FireBase.enumPath.ICONOS_TAREAS);
        await setImgName(IconoTarea.NameFirebase);
      }
      else {
        IconoTarea = {
          PathFireBase: !Fnctn.NullUndefinedEmpty(props.Select_Img) ? props.Select_Img : defaultIcon,
          NameFirebase: !Fnctn.NullUndefinedEmpty(props.Select_ImgName) ? props.Select_ImgName : "sinletras"
        }
        setUrlImage(IconoTarea.PathFireBase);
      }

      let Objt: any = {
        "title": Titulo,
        "icon_path": IconoTarea.PathFireBase,
        "icon_name": IconoTarea.NameFirebase,
        "content": content,
        "attachments": Fnctn.NullUndefinedEmpty(ArchivosAdjuntos) ? [] : ArchivosAdjuntos,
        "has_expiration": selectionVencimiento,
        "expiration_amount": !Fnctn.NullUndefinedEmpty(cantidad) ? parseInt(cantidad) : 0,
        "expiration_time": selectionTiempo,
        "expiration_sequence": selectionSecuencia,
        "category_id": selectionCategoria,
        "send_notification": selectionNotificacion,
        "segmentation_type": VisibleAudiencia,
        "autoAssign": switchAsignacionAutomatica,
        "type_assignment": asignaciones.includes(selectionAsignacion) ? selectionAsignacion : asignacion,
        "from": DesdeFormateado,
        "to": HastaFormateado,
        "priority": "low",
        "evaluation_id": Evaluation_ID,
        "order": Order,
        "expiration_days_notification": diasNotificacionVencimiento
      };
      Objt.segmentation = segmentacion;

      if (FilesAttachments.length > 0) {
        let fileTask: any = await FireBase.uploadFiles(FilesAttachments, FireBase.enumPath.ARCHIVOS_TAREAS);
        let attachmentsfiles = [...ArchivosAdjuntos];

        fileTask.forEach(async (element: any) => {
          const parts = element.NameFile.split('.');
          let extension = parts.length > 1 ? parts.pop() || '' : '';
          let objFileTask = {
            "archive_name": element.NameFile,
            "extension": extension,
            "url": element.PathFireBase,
            "id_firebase": element.NameFirebase
          }
          attachmentsfiles.push(objFileTask);
        });
        Objt.attachments = attachmentsfiles
      }

      if (props.Select_IdTarea === "-1") {
        let a: any = await fetch(`${api}/training/task`,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${data.token}`
            },
            body: JSON.stringify(Objt)
          });
        console.log(await a.json());
        OrderTareas(selectionCategoria);
      }
      else {
        let vbl = await fetch(`${api}/training/task/${props.Select_IdTarea}`,
          {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${data.token}`
            },
            body: JSON.stringify(Objt)
          });
        console.log(await vbl.json());
      }
      halcoloaderRemove();
      notify("Tarea creada correctamente", "success")
    }
    catch (error) {
      halcoloaderRemove();
      notify(error, 'error');
    }
  };

  const linkRelationated = () => {
    let prevVideos = props.Select_Attachments.filter((item: any) => item.extension === ".mp4")
    let prevFiles = props.Select_Attachments.filter((item: any) => item.extension !== ".mp4")
    cardVideo(prevVideos, prevVideos)
    cardFile(prevFiles)
  }


  return (
    <React.Fragment>
      <div className={'content-block dx-card responsive-paddings'} style={{ margin: "30px 0px 30px 190px", width: "80%" }}>
        <div className='btnAtras' style={{ margin: "0px 0px 35px 0px" }}>
          <Link to={'/LibretaTareas'}>
            <Button className='' icon="back" text="Atras"></Button>
          </Link>
        </div>
        <div>
          <div className={'form-avatar'} style={{ cursor: 'pointer', borderRadius: '10%' }} onClick={handleClick}>
            <img
              alt={''}
              src={urlImage}
              style={{ width: '100%', height: '100%', objectFit: 'contain', borderColor: "#000" }}
            />
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />
          </div>
          <div style={{ margin: '0px 0px 0px 15%', width: "80%" }}>
            <TextBox
              label='Titulo'
              placeholder='Debes ingresar un título'
              onValueChanged={(e) => handleTextChanged(e, "Titulo")}
              defaultValue={props.Select_Titulo}
            ></TextBox>
            <Button
              style={{ margin: '15px 0px 0px 0px' }}
              stylingMode='contained'
              type='success'
              text='Guardar'
              onClick={Guardar}
            ></Button>
          </div>
        </div>
      </div>
      <div className={'content-block dx-card responsive-paddings'} style={{ margin: "30px 0px 30px 190px", width: "80%" }}>
        <div className='titleCards'><label style={{ fontSize: '25px' }}>Detalles</label></div>
        <div>
          <HtmlEditor height={"35rem"}
            ref={htmlEditorRef}
          >
            <MediaResizing enabled={true} />
            <ImageUpload fileUploadMode="base64" />
            <Toolbar>
              <Item name="undo" />
              <Item name="redo" />
              <Item name="separator" />
              <Item name="size" acceptedValues={['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']} />
              <Item name="font" acceptedValues={fontValues} />
              <Item name="separator" />
              <Item name="bold" />
              <Item name="italic" />
              <Item name="strike" />
              <Item name="underline" />
              <Item name="separator" />
              <Item name="alignLeft" />
              <Item name="alignCenter" />
              <Item name="alignRight" />
              <Item name="alignJustify" />
              <Item name="separator" />
              <Item name="orderedList" />
              <Item name="bulletList" />
              <Item name="separator" />
              <Item name="separator" />
              <Item name="color" />
              <Item name="background" />
              <Item name="separator" />
              <Item name="link" />
              <Item name="image" />
              <Item name="separator" />
              <Item name="clear" />
              <Item name="blockquote" />
              <Item name="separator" />
              <Item name="insertTable" />
              <Item name="deleteTable" />
              <Item name="insertRowAbove" />
              <Item name="insertRowBelow" />
              <Item name="deleteRow" />
              <Item name="insertColumnLeft" />
              <Item name="insertColumnRight" />
              <Item name="deleteColumn" />
            </Toolbar>
          </HtmlEditor>
        </div>
      </div>
      <div className={'content-block dx-card responsive-paddings'} style={{ margin: "30px 0px 30px 190px", width: "80%" }}>
        <div style={{ margin: '0px 0px 25px 0px' }}><label style={{ fontSize: '25px' }}>Enlaces adjuntos</label></div>
        <div style={{ margin: '0px 0px 20px 20px' }}><label style={{ fontSize: '16px' }}>{labelText}</label></div>
        <div className="fileuploader-container">
          <div className='scrolldiv'>{dtVideos}</div>
          {VisibleTbLink &&
            <TextBox
              value={linkString}
              label='Agregar enlace'
              width={"32%"}
              onValueChange={(e) => handleTextChanged(e, "URL")}
            ></TextBox>
          }
          {!VisibleTbLink && <Button width={"32%"} onClick={(e) => onValueChangedLink(e)}>Agregar nuevo enlace</Button>}
          {VisibleTbLink && <Button width={"32%"} onClick={() => OnFocusOutText()}>Agregar</Button>}
        </div>
        <div style={{ margin: ' 20px 0px 25px 0px' }}><label style={{ fontSize: '25px' }}>Archivos adjuntos</label></div>
        <div className='scrolldiv'>{dtFiles}</div>
        <FileUploader
          selectButtonText="Seleccionar documento"
          labelText=""
          accept="*"
          uploadMode="useForm"
          multiple={true}
          onValueChanged={(e) => onValueChangedFiles(e)}
        />
        <div style={{ margin: '20px 0px 25px 0px' }}><label style={{ fontSize: '25px' }}>Fecha de vencimiento</label></div>
        <div style={{ margin: '0px 0px 20px 20px' }}>
          <RadioGroup
            id="radio-group-with-selection"
            items={OpcionesVencimiento}
            value={selectionVencimiento}
            valueExpr="Codigo"
            displayExpr="Nombre"
            onValueChanged={(e) => changeSelectionRB(e, "Vencimiento")}
          />
          {VisibleVencimiento &&
            <div style={{ display: 'flex', margin: '25px 0px 0px 0px' }}>
              <Cantidad CantidadVal={cantidad} handleTextChanged={handleTextChanged}></Cantidad>
              <Tiempo TiempoVal={selectionTiempo} onValueChangedList={onValueChangedList}></Tiempo>
              <Secuencia SecuenciaVal={selectionSecuencia} onValueChangedList={onValueChangedList}></Secuencia>
              <div style={{ margin: '10px 0px 0px 45px' }}><label style={{ fontSize: '15px' }}> de la fecha de contratación</label></div>
            </div>
          }
        </div>
        <div style={{ margin: '20px 0px 25px 0px' }}><label style={{ fontSize: '25px' }}>Categoría</label></div>
        <div style={{ margin: '0px 0px 20px 20px' }}>
          <Categories onSelectionChanged={handleSelectionCategoria} valueCategory={selectionCategoria}
          ></Categories>
        </div>
        <div style={{ margin: '20px 0px 25px 0px' }}><label style={{ fontSize: '25px' }}>Enviar notificaciones</label></div>
        <div style={{ margin: '0px 0px 20px 20px' }}>
          <Notificaciones NotificacionesVal={selectionNotificacion} onValueChangedList={onValueChangedList}></Notificaciones>
        </div>
        {selectionNotificacion && 
        <div>
        <div style={{ margin: '20px 0px 25px 0px' }}><label style={{ fontSize: '25px' }}>¿Cuantos días antes del vencimiento se debe notificar?</label></div>
        <div style={{ margin: '0px 0px 20px 20px' }}>
          <TextBox
            width={"10%"}
            label='Días'
            onValueChanged={(e) => handleTextChanged(e, "DiasNotificacionVencimiento")}
            defaultValue={diasNotificacionVencimiento}
            // onValueChanged={(e) => handleTextChanged(e, "Titulo")}
            // defaultValue={props.Select_Titulo}
            ></TextBox>
        </div>
        </div>
        }
        <div style={{ margin: '20px 0px 25px 0px' }}>
          <label style={{ fontSize: '25px' }}>Asignación automática</label>
          <Switch
            value={switchAsignacionAutomatica}
            style={{ margin: "0% 0% 0% 70%" }}
            onValueChanged={valueSwitchChanged}
          />
        </div>
        {switchAsignacionAutomatica &&
          <div style={{ margin: '0px 0px 20px 20px', display: "flex" }}>
            <RadioGroup
              id="radio-group-with-selection"
              items={OpcionesAsignacion}
              value={selectionAsignacion}
              valueExpr="Codigo"
              displayExpr="Nombre"
              onValueChanged={(e) => changeSelectionRB(e, "Asignacion")}
            />
          </div>
        }
        {VisibleRangoAsignacion &&
          <div style={{ margin: '0px 0px 20px 20px', display: "flex" }}>
            <DateBox
              label='Desde'
              type="date"
              style={{ margin: "0px 0px 0px 40px" }}
              displayFormat="yyyy-MM-dd"
              onValueChanged={(e) => onValueChangedDateBox(e, "Desde")}
            />
            <DateBox
              label='Hasta'
              type="date"
              style={{ margin: "0px 0px 0px 40px" }}
              displayFormat="yyyy-MM-dd"
              onValueChanged={(e) => onValueChangedDateBox(e, "Hasta")}
            />
          </div>
        }
        <div style={{ margin: '20px 0px 25px 0px' }}><label style={{ fontSize: '25px' }}>Audiencia</label></div>
        <div style={{ margin: '0px 0px 20px 20px', display: "flex" }}>
          <RadioGroup
            id="radio-group-with-selection"
            items={ItemsAudiencia}
            value={VisibleAudiencia}
            valueExpr="Codigo"
            displayExpr="Nombre"
            onValueChanged={(e) => changeSelectionRB(e, "Audiencia")}
            onInitialized={() => {
              linkRelationated();
            }}
          />
          {VisibleAudiencia &&
            <div id="accordion">
              {accordionCm}
            </div>}
        </div>
      </div>
    </React.Fragment>
  )
}


const mapStateToProps = (state: RootState) => {
  return {
    Select_IdTarea: state.LibretaTareas.Id_Tarea,
    Select_Img: state.LibretaTareas.Img,
    Select_ImgName: state.LibretaTareas.ImgName,
    Select_Titulo: state.LibretaTareas.Titulo,
    Select_Content: state.LibretaTareas.Contenido,
    Select_Vencimiento: state.LibretaTareas.Vencimiento,
    Select_Cantidad: state.LibretaTareas.Cantidad,
    Select_Tiempo: state.LibretaTareas.Tiempo,
    Select_Secuencia: state.LibretaTareas.Secuencia,
    Select_Categoria: state.LibretaTareas.Categoria,
    Select_Notificacion: state.LibretaTareas.Notificacion,
    Select_AutoAsignacion: state.LibretaTareas.AutoAsignacion,
    Select_TipoAsignacion: state.LibretaTareas.TipoAsignacion,
    Select_Segmentacion: state.LibretaTareas.Segmentacion,
    Select_SegmentacionType: state.LibretaTareas.SegmentacionType,
    Select_From: state.LibretaTareas.From,
    Select_To: state.LibretaTareas.To,
    Select_Evaluation_Id: state.LibretaTareas.Evaluation_id,
    Select_Attachments: state.LibretaTareas.Attachments,
    select_Order: state.LibretaTareas.Order,
    Select_DiasNotificacionVencimiento: state.LibretaTareas.DiasNotificacionVencimiento
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Task)