import  * as Actions  from './LB_actions'

export interface LB_State {
    Id_Tarea: string,
    Titulo: string,
    Img: string,
    ImgName: string,
    Contenido: string,
    Vencimiento: boolean,
    Cantidad: number,
    Tiempo: string,
    Secuencia: string,
    Categoria: string,
    Notificacion: boolean,
    AutoAsignacion: boolean,
    TipoAsignacion: string,
    Segmentacion: [],
    SegmentacionType: boolean,
    From: string,
    To: string,
    Evaluation_id: any,
    Attachments:[],
    Order: any,
    DiasNotificacionVencimiento: any
}

const initialState: LB_State = {
    Id_Tarea: "",
    Titulo: "",
    Img: "",
    ImgName: "",
    Contenido: "",
    Vencimiento: false,
    Cantidad: 0,
    Tiempo: "",
    Secuencia: "",
    Categoria: "",
    Notificacion: false,
    AutoAsignacion: false,
    TipoAsignacion: "",
    Segmentacion: [],
    SegmentacionType:false,
    From: "",
    To: "",
    Evaluation_id: null,
    Attachments:[],
    Order: 0,
    DiasNotificacionVencimiento: 0
}

const LB_reducer = (state = initialState, action: any ) =>{
    switch(action.type){
        case Actions.SET_TAREA_ID: return {...state, Id_Tarea: action.payload}
        case Actions.SET_TAREA_TITULO: return {...state, Titulo: action.payload}
        case Actions.SET_TAREA_IMG: return {...state, Img : action.payload}
        case Actions.SET_TAREA_IMGNAME: return {...state, ImgName : action.payload}
        case Actions.SET_TAREA_CONTENIDO: return {...state, Contenido: action.payload}
        case Actions.SET_TAREA_VENCIMIENTO: return {...state, Vencimiento: action.payload}
        case Actions.SET_TAREA_CANTIDAD: return {...state, Cantidad: action.payload}
        case Actions.SET_TAREA_TIEMPO: return {...state, Tiempo: action.payload}
        case Actions.SET_TAREA_SECUENCIA: return {...state, Secuencia: action.payload}
        case Actions.SET_TAREA_CATEGORIA: return {...state, Categoria: action.payload}
        case Actions.SET_TAREA_NOTIFICACION: return {...state, Notificacion: action.payload}
        case Actions.SET_TAREA_AUTOASIGNACION: return {...state, AutoAsignacion: action.payload}
        case Actions.SET_TAREA_TIPOASIGNACION: return {...state, TipoAsignacion: action.payload}
        case Actions.SET_TAREA_SEGMENTACION: return {...state, Segmentacion: action.payload}
        case Actions.SET_TAREA_SEGMENTACIONTYPE: return {...state, SegmentacionType: action.payload}
        case Actions.SET_TAREA_FROM: return {...state, From: action.payload}
        case Actions.SET_TAREA_TO: return {...state, To: action.payload}
        case Actions.SET_TAREA_EVALUATION_ID: return {...state, Evaluation_id: action.payload}
        case Actions.SET_TAREA_ATTACHMENTS: return {...state, Attachments: action.payload}
        case Actions.SET_TAREA_ORDER: return {...state, Order: action.payload}
        case Actions.SET_TAREA_DIASNOTIFICACIONVENCIMIENTO: return {...state, DiasNotificacionVencimiento: action.payload}
        default:
            return state
    }
}

export default LB_reducer